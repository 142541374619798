import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { displayRegistrationConfirmDialog } from '../../ducks/Modal.duck';
import { localePart, storedLocale } from '../Wrapper/ClassWrapper';
import TrustBoxContainer from '../Trustpilot/TrustpilotContainer';
import haendlerBundLogo from "../../assets/haendlerbundLogo.png";

import css from './Footer.module.css';

const locale = storedLocale() || localePart();
const englishLocale = locale === "en" ? "en-GB" : "de-DE";

const FooterComponent = props => {
  const { rootClassName, className, intl, onDisplayRegistrationConfirmDialog, isAuthenticated } = props;

  return (
    <div className={css.footer}>
      <div className={css.footerContent}>
        <div className={css.logoColumn}>
          <div className={css.logoRow}>
            <NamedLink className={css.logoIcon} name="LandingPage">
              <Logo format="white" />
            </NamedLink>
          </div>
          <h2 className={css.subTitle1}> <FormattedMessage id="Footer.subTitle1" /></h2>
          <div className={css.buttonSell}>
            {!isAuthenticated && (<a className={css.sellOnVegshelfLink} onClick={() => onDisplayRegistrationConfirmDialog(false)}>
              <span className={css.login}>
                <FormattedMessage id="Footer.SellOnVegshelf" />
              </span>
            </a>)}
          </div>
          <div className={css.buttonRegister}>
            {!isAuthenticated && (<a className={css.registerToShopLink} onClick={() => onDisplayRegistrationConfirmDialog(true)}>
              <span className={css.login}>
                <FormattedMessage id="Footer.RegisterToShop" />
              </span>
            </a>)}
          </div>
          <div className={css.trustpilotContainer}>
            <TrustBoxContainer theme="dark" locale={englishLocale} />
          </div>
        </div>
        <div className={css.linksColumn}>
          <h3 className={css.linkColumnTitle}>
            <FormattedMessage id="Footer.linkColumnTitleForBuyers" />
          </h3>
          {!isAuthenticated &&
            (
              <p className={css.linkItem}>
                <NamedLink className={css.namedLinkItem} name="LandingPage">
                  <FormattedMessage id="Footer.BuyOnVegshelf" />
                </NamedLink>
              </p>
            )
          }
          <p className={css.linkItem}>
            <NamedLink className={css.namedLinkItem} name="HowItWorksForBuyersPage">
              <FormattedMessage id="Footer.HowItWorksForBuyers" />
            </NamedLink>
          </p>
          <p className={css.linkItem}>
            <NamedLink className={css.namedLinkItem} name="BuyerTermsPage">
              <FormattedMessage id="Footer.BuyerTerms" />
            </NamedLink>
          </p>
          {/* TODO: introduce FAQ and newsletter page anchors */}
          {/*<div className={css.linkItem}>
            <NamedLink className={css.namedLinkItem} name="HowItWorksForBuyersPage">
              <FormattedMessage id="Footer.BuyerFAQ" />
            </NamedLink>
          </div>
          <div className={css.linkItem}>
            <a href=""><FormattedMessage id="Footer.BuyerNewsletter" /></a>
          </div>*/}
        </div>
        <div className={css.linksColumn}>
          <h3 className={css.linkColumnTitle}>
            <FormattedMessage id="Footer.linkColumnTitleForSuppliers" />
          </h3>
          {!isAuthenticated &&
            (
              <p className={css.linkItem}>
                <NamedLink className={css.namedLinkItem} name="ApplyToSellPage">
                  <FormattedMessage id="Footer.ApplyToSell" />
                </NamedLink>
              </p>
            )
          }
          <p className={css.linkItem}>
            <NamedLink className={css.namedLinkItem} name="HowItWorksForSuppliersPage">
              <FormattedMessage id="Footer.HowItWorksForSuppliers" />
            </NamedLink>
          </p>
          <p className={css.linkItem}>
            <NamedLink className={css.namedLinkItem} name="SupplierTermsPage">
              <FormattedMessage id="Footer.SupplierTerms" />
            </NamedLink>
          </p>
        </div>
        <div className={css.linksColumn}>
          <p className={css.linkColumnTitle}>
            <FormattedMessage id="Footer.linkColumnTitleFollowUs" />
          </p>
          <p className={css.linkItem}>
            <NamedLink name="BlogPage">
              <FormattedMessage id="Footer.Blog" />
            </NamedLink>
          </p>
          <p className={css.linkItem}>
            <a href="https://www.linkedin.com/company/vegshelf/" target="_blank"><FormattedMessage id="Footer.linkedIn" /></a>
          </p>
          <p className={css.linkItem}>
            <a href="https://www.instagram.com/vegshelf/" target="_blank"><FormattedMessage id="Footer.instagram" /></a>
          </p>
          <p className={css.linkItem}>
            <a href="https://www.tiktok.com/@vegshelf" target="_blank"><FormattedMessage id="Footer.tiktok" /></a>
          </p>
          <p className={css.linkItem}>
            <a href="https://twitter.com/vegshelf" target="_blank"><FormattedMessage id="Footer.twitter" /></a>
          </p>
          <p className={css.linkItem}>
            <a href="https://www.facebook.com/vegshelf" target="_blank"><FormattedMessage id="Footer.facebook" /></a>
          </p>
        </div>
        <div className={css.linksColumn}>
          <p className={css.linkColumnTitle}>
            <FormattedMessage id="Footer.linkColumnTitleCompany" />
          </p>
          <p className={css.linkItem}>
            <NamedLink className={css.namedLinkItem} name="AboutUsPage">
              <FormattedMessage id="Footer.aboutUs" />
            </NamedLink>
          </p>
          <p className={css.linkItem}>
            <NamedLink className={css.namedLinkItem} name="WhyVeganPage">
              <FormattedMessage id="Footer.whyVegan" />
            </NamedLink>
          </p>
          <p className={css.linkItem}>
            <a href="https://angel.co/company/vegshelf" target="_blank"><FormattedMessage id="Footer.jobs" /></a>
          </p>
          <p className={css.linkItem}>
            <NamedLink className={css.namedLinkItem} name="ContactUsPage">
              <FormattedMessage id="Footer.ContactUs" />
            </NamedLink>
          </p>
        </div>
      </div>
      <div className={css.bottomSector}>
        <div className={css.copyright}>
          <FormattedMessage id="Footer.copyright" />
          <div className={css.verticalBar}>
            <FormattedMessage id="Footer.verticalBar" />
          </div>
          <p className={css.bottomLink}>
            <NamedLink className={css.namedLinkItem} name="ImprintPage">
              <FormattedMessage id="Footer.Imprint" />
            </NamedLink>
          </p>
          <div className={css.verticalBar}>
            <FormattedMessage id="Footer.verticalBar" />
          </div>
          <p className={css.bottomLink}>
            <NamedLink className={css.namedLinkItem} name="BuyerTermsPage">
              <FormattedMessage id="Footer.termsOfService" />
            </NamedLink>
          </p>
          <div className={css.verticalBar}>
            <FormattedMessage id="Footer.verticalBar" />
          </div>
          <p className={css.bottomLink}>
            <NamedLink className={css.namedLinkItem} name="PrivacyPolicyPage">
              <FormattedMessage id="Footer.PrivacyPolicy" />
            </NamedLink>
          </p>
          <div className={css.verticalBar}>
            <FormattedMessage id="Footer.verticalBar" />
          </div>
          <p className={css.bottomLink}>
            <NamedLink className={css.namedLinkItem} name="CookiePolicyPage">
              <FormattedMessage id="Footer.cookiePolicy" />
            </NamedLink>
          </p>
        </div>
        <div className={css.paymentLogos}>
          <div className={css.visaLogo}></div>
          <div className={css.mastercardLogo}></div>
          <div className={css.amexLogo}></div>
        </div>
      </div>
    </div >
  );
};
FooterComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

FooterComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  return {
    isAuthenticated
  };
};

const mapDispatchToProps = dispatch => ({
  onDisplayRegistrationConfirmDialog: (isBuyer) => dispatch(displayRegistrationConfirmDialog(true, isBuyer)),
});

const Footer = compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(FooterComponent);

export default Footer;
